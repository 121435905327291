@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Nunito:ital,wght@0,200;0,400;1,700&display=swap');

.text-nomargin{
    margin-bottom: 0px!important;
}

.courgette{
    font-family: 'Courgette', cursive!important;
}

.nunito{
    font-family: 'Nunito', sans-serif!important;
}

.nunito-italic-bold{
    font-family: 'Nunito', sans-serif!important;
    font-weight: 700!important;
    font-style: italic!important;
}

.nunito-bold{
    font-family: 'Nunito', sans-serif!important;
    font-weight: 700!important;
}

.nunito-light{
    font-family: 'Nunito', sans-serif!important;
    font-weight: 200!important;
}

.font-3{
    font-size: 3rem!important;
}

.font-2-5{
    font-size: 2.5rem!important;
}

.font-2{
    font-size: 2rem!important;
}

.font-1-5{
    font-size: 1.5rem!important;
}

.font-1{
    font-size: 1rem!important;
}

@media (max-width: 766px) {
    .font-3{
        font-size: 2.5rem!important;
    }
    
    .font-2-5{
        font-size: 2.08rem!important;
    }
    
    .font-2{
        font-size: 1.66rem!important;
    }
    
    .font-1-5{
        font-size: 1.25rem!important;
    }
    
    .font-1{
        font-size: 0.83rem!important;
    }
}

@media (max-width: 380px) {
    .font-3{
        font-size: 2rem!important;
    }
    
    .font-2-5{
        font-size: 1.66rem!important;
    }
    
    .font-2{
        font-size: 1.25rem!important;
    }
    
    .font-1-5{
        font-size: 0.9rem!important;
    }
    
    .font-1{
        font-size: 0.66rem!important;
    }
}