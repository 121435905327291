html,
body,
#root {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  height: 100vh;
  max-height: 100vh;
}

a {
  text-decoration: none !important;
}

::selection {
  color: #281852;
  background: #FF6D00;
}

*::-webkit-scrollbar {
  width: 6px;
  /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
  background: #130B27;
  /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: #FF8500;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: transparent
    /* creates padding around scroll thumb */
}