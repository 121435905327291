$custom-purple-lighter: #683DD1;
$custom-purple-medium: #281852;
$custom-purple-darker: #130B27;

$custom-white: #FAF9F9;

$custom-orange-lighter: #FF9E00;
$custom-orange-medium: #FF8500;
$custom-orange-darker: #FF6D00;

.color-white{
    color: $custom-white!important;
}

.color-orange{
    color: $custom-orange-medium!important;
}

.color-purple{
    color: $custom-purple-medium!important;
}

.color-purple-darker{
    color: $custom-purple-darker!important;
}

.orange-gradient-bg {
    background: linear-gradient(135deg, rgba(255, 158, 0, 1) 0%, rgba(255, 109, 0, 1) 100%) !important;
}

.purple-gradient-bg {
    background: linear-gradient(135deg, rgba(104, 61, 209, 1) 0%, rgba(19, 11, 39, 1) 140%) !important;
}