@import './custom_colors.scss';

.main-container {
    width: 100%;
    min-height: 100vh;
    margin-left: 0px !important;
    margin-right: 0px !important;

    .col {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.text-body{
    margin-bottom: 100px!important;
}

.header{
    padding-bottom: 80px;
}

.row{
    justify-content: space-evenly;
}

.home-text{
    margin-bottom: 0px;
}

.home-title{
    margin-bottom: 16px;
}

.devices-img {
    max-width: 526px;
    max-height: 240px;
    filter: drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.25));
}

.device-col{
    padding-bottom: 100px;
}

.divisor-img {
    width: 200px;
    margin-bottom: 16px;
}

.register-button-home {
    --bs-btn-padding-y: 1rem!important; --bs-btn-padding-x: 2rem!important;
    margin-top: 16px;
    color: $custom-purple-medium !important;
    border-radius: 100px !important;
    border-color: $custom-orange-medium !important;
    filter: drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.25));
}

.register-button-home:hover {
    border-color: $custom-purple-medium !important;
    color: $custom-orange-medium !important;
    background: linear-gradient(135deg, rgba(104, 61, 209, 1) 0%, rgba(19, 11, 39, 1) 140%) !important;
}

@media (max-width: 991px) {
    .text-body{
        max-width: 600px!important;
    }
}

@media (max-width: 766px) {
    .devices-img {
        min-width: 230px;
    }
}