@import './custom_colors.scss';

$offcanvas-horizontal-width:        300px;
$offcanvas-transition-duration:     .5s;
$offcanvas-bg-color:                $custom-purple-darker;
$offcanvas-border-width:            0px;

//import to booststrap
@import "~bootstrap/scss/bootstrap";

.nav {
    padding-top: 1.5rem;
}

.brand {
    width: 70px;
    height: auto;
    margin-right: 1rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.hamburguer {
    width: 35px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.hamburguer-button {
    --bs-btn-padding-y: 0px;
    --bs-btn-padding-x: 0px;
}

.register-button {
    color: $custom-purple-medium !important;
    margin-left: 32px;
    width: 110px !important;
    height: 50px !important;
    border-radius: 100px !important;
    border-color: $custom-orange-medium !important;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.register-button:hover {
    border-color: $custom-purple-medium !important;
    color: $custom-orange-medium !important;
    background: linear-gradient(135deg, rgba(104, 61, 209, 1) 0%, rgba(19, 11, 39, 1) 140%) !important;
}

.brand-title {
    color: $custom-white  !important;
    text-decoration: none !important;
}

// Animação Botão Nav

.cta {
    border: none;
    background: none;
}

.cta span {
    padding-bottom: 7px;
}

.hover-underline-animation {
    position: relative;
    padding-bottom: 20px;
}

.hover-underline-animation-card {
    position: relative;
    padding-bottom: 20px;
}

.hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1.5px;
    bottom: 0;
    left: 0;
    background-color: $custom-orange-medium;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation-card:hover {
    transition: 0.5s;
}

.hover-underline-animation-card:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1.5px;
    bottom: 0;
    left: 0;
    background-color: $custom-orange-medium;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.cta:hover .hover-underline-animation:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.cta:hover .hover-underline-animation-card:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

@media (max-width: 766px) {
    .brand {
        width: 50px;
        height: 50px;
    }

    .register-button {
        margin-top: 32px;
        margin-left: 0px;
    }
}

@media (max-width: 380px) {
    .brand {
        width: 38px;
        height: 38px;
        margin-right: 0.5rem;
    }
}