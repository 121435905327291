@import './custom_colors.scss';

.modal-backdrop.show{
    opacity: 0!important;
}

.note-card {
    border: 3px solid $custom-purple-darker;
    box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    padding: 12px 12px 0px 12px;
    width: 240px;
    min-height: 160px;
}

.delete-icon {
    width: 20px;
}

.delete-button {
    --bs-btn-padding-y: 0px !important;
    --bs-btn-padding-x: 0px !important;
    border: none !important;
    margin-right: 16px;
}

.title-note {
    margin: 0px;
}

.divisor-note {
    width: 100px;
}