@import './custom_colors.scss';

.nav {
    padding-top: 1.5rem;
    position: sticky;
    top: 0;
    left: 0;
}

.brand {
    width: 70px;
    height: auto;
    margin-right: 1rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.userIcon {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.user-text {
    margin-bottom: 0px;
    padding: 0px 12px;
}

.dropdown-icon {
    background-color: transparent !important;
    border-color: transparent !important;
}

.dropdown-icon:hover {
    background-color: $custom-orange-darker !important;
    border-color: transparent !important;
}

@media (max-width: 766px) {
    .brand {
        width: 50px;
        height: 50px;
    }
}


@media (max-width: 380px) {
    .brand {
        width: 38px;
        height: 38px;
        margin-right: 0.5rem;
    }
}